import React from "react";
import "./Works.scss"
import { Item, List } from 'semantic-ui-react'
import { map } from "lodash";
export function Works(props) {
    const { work } = props;


    return (
        <Item className="work-container">

            <div className="work-container-div">
                <Item.Content>
                    <Item.Header as='a' className="work-container__title">{work.bussiness}</Item.Header>
                    <Item.Meta className="work-container__date">{work.initDate}-{work.finishDate}</Item.Meta>
                    <Item.Description className="work-container__description">
                        {work.description}
                    </Item.Description>
                    <Item.Description className="work-container__description">
                        <List bulleted>

                            {map(work.thingsToDo, (thing) => (
                                <div key={thing._id} className="item">
                                    {thing}
                                </div>
                            ))}
                        </List>
                    </Item.Description>
                    <Item.Extra className="work-container__link"><a href={`https://${work.website}`}>{work.website}</a></Item.Extra>
                </Item.Content>
            </div>
        </Item>


    );
}
