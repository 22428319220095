
import React from "react";
import "./ListCourses.scss";
import { Item } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import { BASE_PATH } from "../../../utils/constants";

export function ListCourses(props) {
  const { listCourses, language, coursesPerPage, totalCourses, paginate } = props;
  const pageNumbers = [];

  // Creem un array amb el nombre de pàgines
  for (let i = 1; i <= Math.ceil(totalCourses / coursesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="list-courses">
      <Item className="course-container">
        {map(listCourses, (course) => (
          <div key={course._id} className="course-container-div">
            <Item.Image
              src={`${BASE_PATH}${course.image[0].url}`}
              className="course-container-div__image"
            />
            <Item.Content>
              <Item.Header as="a" className="course-container-div__title">
                {course.title}
              </Item.Header>
              <Item.Meta className="course-container-div__date">
                {course.date}
              </Item.Meta>
              <Item.Description className="course-container-div__from">
                {course.from}
              </Item.Description>
              <Item.Extra className="course-container-div__credential">
                <a href={`${course.credential}`}>
                  {language === "en" ? "Credential" : "Credenciales"}
                </a>
              </Item.Extra>
            </Item.Content>
          </div>
        ))}
      </Item>
      {/* Paginació */}
      <div className="pagination">
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className="pagination-button">
            {number}
          </button>
        ))}
      </div>
    </div>
  );
}
