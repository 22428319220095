import { BASE_PATH } from "../utils/constants";

export async function blog(language) {
    try {


        const url = `${BASE_PATH}/blogs?_sort=created_at:DESC`;
        const response = await fetch(url);
        const result = await response.json();
        console.log("blog = ", result);
        if (language == "en") {
            const filteredResult = result.filter(item => item.language === "en");
            console.log("en = ", filteredResult)
            return filteredResult;

        }
        else {
            const filteredResult = result.filter(item => item.language === "es");
            console.log("es = ", filteredResult)
            return filteredResult;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}



export async function getPost(path_recieved) {

    try {
        console.log(path_recieved)
        const url = `${BASE_PATH}/blogs/${path_recieved}`;
        console.log("url = ", url)
        const response = await fetch(url);
        const result = await response.json();
        console.log("result", result);
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
} 