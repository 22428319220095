import { BASE_PATH } from "../utils/constants";

export async function whatIDo(language) {
    try {
        const url = `${BASE_PATH}/what-i-dos`;
        const response = await fetch(url);
        const result = await response.json();
        if (language == "en") {
            const filteredResult = result.filter(item => item.language === "en");
            console.log("en = ", filteredResult)

            return filteredResult;

        }
        else {
            const filteredResult = result.filter(item => item.language === "es");
            console.log("es = ", filteredResult)
            return filteredResult;


        }
    } catch (error) {
        console.log(error);
        return null;
    }
}