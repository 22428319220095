import { BASE_PATH } from "../utils/constants";

export async function getPrograms() {
    try {
        const url = `${BASE_PATH}/programs`;
        const response = await fetch(url);
        const result = await response.json();
        console.log(result)
        return result[0].program;
    } catch (error) {
        console.log(error);
        return null;
    }
}