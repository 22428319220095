import "./NotGoodScreen.scss"
import { Icon } from 'semantic-ui-react'
export function NotGoodSCreen() {

    return (
        <div className="not-good-screen">
            <h1 className="not-good-screen_h1">For the best experience, try opening this page on your computer or press the <Icon name='ellipsis vertical' /> button and select the desktop view.</h1>
        </div>

    )
}