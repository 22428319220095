import { BASE_PATH } from "../utils/constants";

export async function getCurriculum() {
    try {
        const url = `${BASE_PATH}/download-pdfs`;
        const response = await fetch(url);
        const result = await response.json();
        console.log(result);
        return result[0].curriculum;
    } catch (error) {
        console.log(error);
        return null;
    }
}