import React, { useState, useEffect } from "react";
import "./Post.scss"
import { getPost, blog } from "../../../api/blog";
import { Link, useParams } from "react-router-dom";
import { Button, Loader, Icon } from "semantic-ui-react";
import { marked } from 'marked';
import { Helmet } from 'react-helmet';


export function Post(props) {
    const [post, setPost] = useState(null)
    const [fullPosts, setFullPosts] = useState(null)
    const { path } = useParams();
    const { language } = props;

    useEffect(() => {
        (async () => {
            console.log(path)
            const response = await getPost(path);
            setPost(response);
            console.log("response", response)
        })();
    }, [language]);




    if (!post) return <Loader active inline="centered" />

    return (
        <div className="post">

            <div className="post_title">
                <h1 >{post.title}</h1>
            </div>

            <div className="post_content"
                dangerouslySetInnerHTML={{ __html: marked(post.content) }}
            />
            {post.github ? (
                <div className="post_github">
                    <a href={`${post.github}`}>
                        <Button> <Icon name="github" size="big" ></Icon>GITHUB</Button>
                    </a>
                </div>
            ) : null}

        </div>

    )
}