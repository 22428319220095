import React, { useState, useEffect } from "react";
import { Container } from "semantic-ui-react"
import "./ButtonGroup.scss"
import { Image, Button, Icon } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { BASE_PATH } from "../../utils/constants"
import { getCurriculum } from "../../api/getCurriculum";

export function ButtonGroup() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [error, setError] = useState(null);

  const [curriculumUrl, setCurriculumUrl] = useState(null)

  useEffect(() => {
    (async () => {
      const response = await getCurriculum();
      setCurriculumUrl(response[0].url);
      console.log("response", response[0].url)

    })();
  }, []);


  const handleDownload = async () => {
    try {
      console.log(curriculumUrl)
      const response = await fetch(`${BASE_PATH}${curriculumUrl}`);
      console.log("response ", response)
      const blob = await response.blob();
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "curriculum.pdf";
      console.log("downloading");
      link.click();
    } catch (err) {
      setError("Error al descargar el archivo");
      console.log(error)
    }
  };


  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (

    <div className="button-container">
      {screenWidth > 768 ? (
        <>
          <Button.Group vertical className="button-container__home">
            <Link to="/">
              <a>
                <Button className="buttonLayout-home" animated="fade">
                  <Button.Content visible><Icon name='home' size="big" /></Button.Content>
                  <Button.Content hidden color="orange">
                    Home
                  </Button.Content>
                </Button>
              </a>
            </Link>
            <Link to="/About">

              <Button className="buttonLayout" animated="fade">
                <Button.Content visible ><Icon name='user outline' size="big" /></Button.Content>
                <Button.Content hidden >
                  About
                </Button.Content>
              </Button>
            </Link>

            <Link to="/Courses">

              <Button className="buttonLayout" animated="fade">
                <Button.Content visible><Icon name='book' size="big" /></Button.Content>
                <Button.Content hidden>
                  Knowledge
                </Button.Content>
              </Button>
            </Link>


            <Link to="/Contact">
              <Button className="buttonLayout" animated="fade">
                <Button.Content visible><Icon name='at' size="big" /></Button.Content>
                <Button.Content hidden>
                  Contact
                </Button.Content>
              </Button>
            </Link>
            <Link to="/blogs">
              <Button className="buttonLayout" animated="fade">
                <Button.Content visible><Icon name='edit' size="big" /></Button.Content>
                <Button.Content hidden>
                  Blog
                </Button.Content>
              </Button>
            </Link>



            <Button className="buttonLayout-download" animated="fade" onClick={handleDownload}>
              <Button.Content visible><Icon name='download' size="big" /></Button.Content>
              <Button.Content hidden>
                Download
              </Button.Content>
            </Button>


          </Button.Group>
        </>) : (
        <>
          <Button.Group vertical className="button-container__home">
            <Link to="/">
              <a>
                <Button className="buttonLayout" animated="fade">
                  <Button.Content visible><Icon name='home' /></Button.Content>
                  <Button.Content hidden>
                    Home
                  </Button.Content>
                </Button>
              </a>
            </Link>
            <Link to="/About">

              <Button className="buttonLayout" animated>
                <Button.Content visible><Icon name='user outline' /></Button.Content>
                <Button.Content hidden>
                  About
                </Button.Content>
              </Button>
            </Link>
            <Link to="/Blog">
              <Button className="buttonLayout" animated>
                <Button.Content visible><Icon name='edit' /></Button.Content>
                <Button.Content hidden>
                  Blog
                </Button.Content>
              </Button>
            </Link>
            <Link to="/Contact">
              <Button className="buttonLayout" animated>
                <Button.Content visible><Icon name='at' /></Button.Content>
                <Button.Content hidden>
                  Contact
                </Button.Content>
              </Button>
            </Link>
            <Link to="/Courses">

              <Button className="buttonLayout" animated>
                <Button.Content visible><Icon name='book' /></Button.Content>
                <Button.Content hidden>
                  Courses
                </Button.Content>
              </Button>
            </Link>



            <Link to="/Downloads">
              <Button className="buttonLayout" animated >
                <Button.Content visible ><Icon name='download' /></Button.Content>
                <Button.Content hidden >
                  Download
                </Button.Content>
              </Button>
            </Link>
          </Button.Group>
        </>
      )}
    </div>
  );
}