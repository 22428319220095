import React from "react";
import { ListPosts } from "./ListPosts/ListPosts";
import "./Blog.scss"
export function Blog(props) {
    const {language} = props;
    return (
        <div className="blog-container">
            <div className="blog-container__listPosts">
                <ListPosts language={language} />

            </div>
        </div>

    )
}