import React from "react";
import "./Languages.scss"
import { Item } from 'semantic-ui-react'
import { map } from "lodash";
export function Languages(props) {
    const { languages } = props;
    console.log(languages)
    return (
        <Item className="language-container">
            {map(languages, (language) => (

                <div key={language._id} className="language-container-div">
                    {console.log(language)}
                    <Item.Content>
                        <Item.Header as='a' className="language-container__title">{language.language}</Item.Header>
                        <Item.Description className="language-container__level">
                            {language.level}
                        </Item.Description>

                    </Item.Content>
                </div>


            ))}


        </Item>


    );
}
