import React, { useEffect } from "react";
import "./ListPostItem.scss"
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom"
import { BASE_PATH } from "../../../../utils/constants";
import "./ListPostItem.scss"

export function ListPostItem(props) {
    const { post, language } = props;
    console.log("path", post.path)



    return (

        <Link className="list-post-item" to={`/blogs/${post.path_value}`}>
            <div className="list-post-item__image">
                <Image src={`${BASE_PATH}${post.miniature[0].url}`} fluid />

            </div>
            <div className="list-post-item__data">
                <h2>{post.title}</h2>
                <span>{post.created_at}</span>
            </div>



        </Link>

    )
}