import React from 'react'
import { Button } from 'semantic-ui-react'



export function LanguageButton(props) {
    const { setLanguage } = props;



    return (
        <Button.Group size='large'>
            <Button onClick={() => setLanguage('en')}>en</Button>
            <Button.Or />
            <Button onClick={() => setLanguage('es')}>es</Button>
        </Button.Group>
    )
}