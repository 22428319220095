import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { WebRouter } from './router';

function App() {
  return (
    <BrowserRouter>
      <WebRouter></WebRouter>
    </BrowserRouter>

  );
}

export default App;
