import React, { useState, useEffect } from "react";
import "./ClientLayout.scss"
import { Image } from 'semantic-ui-react'
import { getAboutUsApi } from "../../api/user";
import { BASE_PATH } from "../../utils/constants";
import { ButtonGroup } from "../../Components/ButtonGroup/ButtonGroup";
import { NotGoodSCreen } from "../../Components/NotGoodScreen/NotGoodScreen";
import { LanguageButton } from "../../Components/LanguageButton/LanguageButton"
//import miImagen from '../../assets/jpg/mountains.jpg';


export function ClientLayout(props) {
  const [aboutUsData, setAboutUsData] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { language, setLanguage } = props;

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    (async () => {
      const response = await getAboutUsApi(language);
      setAboutUsData(response);
    })();
  }, [language]);



  return (
    <div className="container">
      {screenWidth > 768 ? (
        <>
          <div className="left-container">
            <div className="left-container__image">
              {aboutUsData != null ? (<Image src={`${BASE_PATH}${aboutUsData[0].image.url}`} fluid />) : null}
              <ButtonGroup />

            </div>
          </div>
          <div className="right-container">

            <div className="scroll-container">
              <LanguageButton className="languages" setLanguage={setLanguage} />
              {props.children}
            </div>
          </div>

        </>) :
        (<NotGoodSCreen />)}

    </div>
  );
}

