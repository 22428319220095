import React from "react";
import "./Studies.scss"
import { Item, List } from 'semantic-ui-react'
import { map } from "lodash";
export function Studies(props) {
    const { study } = props;

    return (
        <Item className="studies-container">
            <div className="studies-container-div">
                <Item.Content>
                    <Item.Header as='a' className="studies-container__title">{study.Title}</Item.Header>
                    <Item.Description className="studies-container__description">
                        {study.universidad}
                    </Item.Description>
                    <Item.Meta className="studies-container__date">{study.inicio}-{study.fin}</Item.Meta>
                </Item.Content>
            </div>
        </Item>
    );
}
