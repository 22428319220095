import React, { useState, useEffect } from "react";
import { Home } from "../pages/web/Home"
import { Blog } from "../pages/web/Blog"
import { About } from "../pages/web/About"
import { Contact } from "../pages/web/Contact"
import { Courses } from "../pages/web/Courses"
import { Post } from "../pages/web/Post"


import { Routes, Route } from "react-router-dom"
import { ClientLayout } from "../layouts/ClientLayout";
import { map } from "lodash";

export function WebRouter() {
    const [language, setLanguage] = useState("en");

    const loadLayout = (Layout, Page) => {
        return (
            <Layout language={language} setLanguage={setLanguage}>
                <Page language={language} setLanguage={setLanguage} />
            </Layout>
        )
    }
    return (
        <Routes>
            <Route path="/" element={loadLayout(ClientLayout, Home)} />
            <Route path="/blogs" element={loadLayout(ClientLayout, Blog)} />
            <Route path="/about" element={loadLayout(ClientLayout, About)} />
            <Route path="/contact" element={loadLayout(ClientLayout, Contact)} />
            <Route path="/courses" element={loadLayout(ClientLayout, Courses)} />
            <Route path="/blogs/:path" element={loadLayout(ClientLayout, Post)} />



        </Routes>
    )
}