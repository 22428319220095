import React, { useEffect, useState } from "react";
import { blog } from "../../../../api/blog";
import "./ListPosts.scss"
import { Loader, Pagination } from "semantic-ui-react"
import { map } from "lodash";
import { ListPostItem } from "../ListPostItem/ListPostItem";

export function ListPosts(props) {

    const [posts, setPosts] = useState(null);
    const [pagination, setPagination] = useState();
    const [page, setPage] = useState(1)
    const {language} = props;

    useEffect(() => {
        (async () => {
            const response = await blog(language);
            setPosts(response);
            console.log("list blog = ",response)

        })();
    }, [language]);

    const changePage = (_, data) => {
        const newPage = data.activePage;
        setPage(newPage);
    }

    if (!posts) return <Loader active inline="centered" />

    return (
        <div className="list-posts-web">
            <div className="list-posts-web__list">
                {map(posts, (post) => (
                    <div key={post._id} className="list-posts-web__item">
                        <ListPostItem post={post} language={language} />
                    </div>
                ))}
            </div>


        </div>

    )
}