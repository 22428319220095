import { Card } from 'semantic-ui-react'
import "./WhatIDo.scss"

export function WhatIDo(props) {
    const { thing } = props;

    return (
            <Card
                link
                header={thing.title}
                
                description={[
                    thing.description,
                ].join('')}
                className="WhatIDo-container__card"
            />

    )
}