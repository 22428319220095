



import "./AboutInfo.scss"

export function AboutInfo(props) {
    const { aboutMe } = props;

    return (
        <div className="About-container">
            <div className="About-container__AboutMe__info">

                <div className="About-container__AboutMe__info__pack">
                    <div className="About-container__AboutMe__info__pack__1"><h2>Birthday: </h2> </div>
                    <div className="About-container__AboutMe__info__pack__2"><p>{aboutMe ? aboutMe[0].birthday : null}</p></div>
                </div>
                <div className="About-container__AboutMe__info__pack">
                    <div className="About-container__AboutMe__info__pack__1"><h2>Adress: </h2> </div>
                    <div className="About-container__AboutMe__info__pack__2"><p>{aboutMe ? aboutMe[0].adress : null}</p></div>
                </div>
                <div className="About-container__AboutMe__info__pack">
                    <div className="About-container__AboutMe__info__pack__1"><h2>Phone: </h2> </div>
                    <div className="About-container__AboutMe__info__pack__2"><p>{aboutMe ? aboutMe[0].phoneNumber : null}</p></div>
                </div>
                <div className="About-container__AboutMe__info__pack">
                    <div className="About-container__AboutMe__info__pack__1"><h2>Email: </h2> </div>
                    <div className="About-container__AboutMe__info__pack__2"><p>{aboutMe ? aboutMe[0].mail : null}</p></div>
                </div>

            </div>

        </div>

    )
}