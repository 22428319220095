import { BASE_PATH } from "../utils/constants";

export async function sendEmail(values) {

    const url = `${BASE_PATH}/send-emails`;

    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            mail: values.identifier,
            message: values.text,
        }),
    });
    const data = await response.json();
    if (response.ok) {
        console.log(data);
    } else {
        console.log(data);
    }
}
