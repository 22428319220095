import React, { useEffect } from "react";
import "./sphere.scss";

// Importing TagCloud package
import TagCloud from "TagCloud";

export function Sphere(props) {
  const { texts } = props;
  // Animation settings for Text Cloud
  useEffect(() => {
    return () => {
      const container = ".tagcloud";


      const options = {
        radius: 300,
        maxSpeed: "normal",
        initSpeed: "normal",
        keep: true,

      };

      TagCloud(container, texts, options);
    };
  }, []);

  return (
    <>
      <div className="text-shpere">
        {console.log(TagCloud.item)}
        <span className="tagcloud" ></span>
      </div>
    </>
  );
};

