// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Importing google font  */\n.text-shpere {\n  top: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.tagcloud {\n  display: inline-block;\n  top: 0;\n  left: 0;\n  font-family: \"Inter\", sans-serif;\n  font-weight: 600;\n  letter-spacing: 0.0625em;\n  font-size: 1.3em;\n}\n\n/* Change color of each text in sphere on hover   */\n.tagcloud--item {\n  color: black;\n  text-transform: uppercase;\n}\n\n.tagcloud--item:hover {\n  color: #ff6347;\n}", "",{"version":3,"sources":["webpack://./src/Components/Sphere/sphere.scss"],"names":[],"mappings":"AAAA,2BAAA;AAGA;EACI,MAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,qBAAA;EACA,MAAA;EACA,OAAA;EACA,gCAAA;EACA,gBAAA;EACA,wBAAA;EACA,gBAAA;AAAJ;;AAGA,mDAAA;AACA;EACI,YAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,cAAA;AAAJ","sourcesContent":["/* Importing google font  */\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap');\n\n.text-shpere {\n    top: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.tagcloud {\n    display: inline-block;\n    top: 0;\n    left: 0;\n    font-family: 'Inter', sans-serif;\n    font-weight: 600;\n    letter-spacing: 0.0625em;\n    font-size: 1.3em;\n}\n\n/* Change color of each text in sphere on hover   */\n.tagcloud--item {\n    color: black;\n    text-transform: uppercase;\n}\n\n.tagcloud--item:hover {\n    color: #ff6347;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
