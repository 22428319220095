import React, { useState, useEffect } from "react";
import { getAboutUsApi } from "../../../api/user"
import { whatIDo } from "../../../api/whatIdo"
import "./About.scss"
import { AboutMe } from "../../../Components/About/AboutGroup/AboutMe";
import { AboutInfo } from "../../../Components/About/AboutGroup/AboutInfo";
import { WhatIDo } from "../../../Components/About/WhatIDo/WhatIDo";
import { Works } from "../../../Components/About/Works/Works";
import { getWorks } from "../../../api/getWorks";
import { map } from "lodash";
export function About(props) {
    const [aboutMe, setAboutMe] = useState(null);
    const [whatIdo, setWhatIDo] = useState(null);
    const [works, setWorks] = useState(null);
    const { language } = props;

    useEffect(() => {
        (async () => {
            const response = await getAboutUsApi(language);
            setAboutMe(response);
        })();
    }, [language]);

    useEffect(() => {
        (async () => {
            const response = await whatIDo(language);
            console.log(response)
            setWhatIDo(response);
        })();
    }, [language]);

    useEffect(() => {
        (async () => {
            const response = await getWorks(language);
            console.log(response)
            setWorks(response);
        })();
    }, [language]);

    return (
        <div className="About-container">
            <div className="About-container__AboutMe">

                <div className="About-container__AboutMe__description">
                    <AboutMe aboutMe={aboutMe} language={language} />
                </div>

                {/* <div className="About-container__AboutMe__info">
                    <AboutInfo aboutMe={aboutMe} />
                </div> */}

            </div>
            <div className="WhatIDoTitle">{language == "en" ? "What I do:" : "Que hago:"}</div>

            <div className="WhatIDo">

                {map(whatIdo, (thing) => (
                    <div key={thing._id} className="item">
                        <WhatIDo thing={thing} />
                    </div>
                ))}
            </div>
            <br />
            <div className="WhatIDoTitle">{language == "en" ? "Where I've been working:" : "Donde he trabajado:"}</div>
            <div className="Works-container">

                {map(works, (work) => (
                    <div key={work._id} className="item">
                        <Works work={work} />
                    </div>
                ))}
            </div>
            <br />
        </div>

    )
}